@if (hideOutline) {
    <dt-link [icon]="icon" [color]="color" [tooltipIfEllipsed]="text">
        {{ text }}
    </dt-link>
}
@if (!hideOutline) {
    <dt-outline-button [icon]="icon" [color]="color" [tooltipIfEllipsed]="text" [disabled]="disabled">
        {{ text }}
    </dt-outline-button>
}
