<dt-popup>
    <div class="popup-content" #content popup-content>
        <div class="header text-content-bold" [ngStyle]="{ 'box-shadow': canScrollUp ? '0px 2px 5px var(--bg-neutral-weak)' : 'none' }">
            <div class="title">
                <dt-background-icon [icon]="huiswerkType | huiswerkTypeIcon" [color]="huiswerkType | huiswerkTypeColor">
                </dt-background-icon>
                <span>{{ huiswerkType | huiswerkTypeTitel }}</span>
            </div>
            <div class="info hide-for-desktop">
                <dt-lesuur
                    class="lesuur"
                    [beginlesuur]="afspraak.lesuur"
                    [eindlesuur]="afspraak.eindLesuur"
                    [isNu]="afspraak.isNu"
                    [isRoosterToets]="afspraak.isRoosterToets"
                    [hmyTooltip]="afspraak | roosterToets"
                    [alignCenter]="false"
                    [maxWidth]="300"
                    [onTouchAllowed]="true"></dt-lesuur>
                <div class="lesgroepen text-content-semi">
                    <span class="lesgroep">{{ afspraak | afspraakTitel }}</span>
                </div>
                <span class="datum">{{ afspraak.begin | dtDate: 'dag_kort_dagnummer_maand_kort' }}</span>
            </div>
        </div>
        @if (roosterPreviews) {
            <div class="preview-container" #previewContainer (scroll)="updateScrollValues()">
                @for (preview of roosterPreviews; track preview) {
                    <div class="preview">
                        @if (preview.tijdsindicatie) {
                            <hmy-icon-pill
                                class="tijdsindicatie"
                                [text]="preview.tijdsindicatie"
                                hmyTooltip="Tijdsindicatie"
                                icon="klok"
                                iconSize="smallest">
                            </hmy-icon-pill>
                        }
                        @if (preview.onderwerp) {
                            <div class="preview-onderwerp text-content-semi">
                                {{ preview.onderwerp }}
                            </div>
                        }
                        <div class="preview-omschrijving" [innerHTML]="preview.omschrijving"></div>
                    </div>
                }
                @if (moetOverflowGradientTonen) {
                    <div class="overflow-gradient"></div>
                }
            </div>
        } @else {
            <div class="spinner">
                <hmy-spinner />
            </div>
        }
        <div class="lesplanning-link show-for-desktop action-primary-normal" data-gtm="naar-lesplanning">
            <a [routerLink]="['/rooster/les', afspraak.id, 'lesplanning']">Naar lesplanning</a>
        </div>
        <div class="buttons hide-for-desktop" [ngStyle]="{ 'box-shadow': canScrollDown ? '0px -2px 5px var(--bg-neutral-weak)' : 'none' }">
            <dt-outline-button [routerLink]="['/rooster/les', afspraak.id, 'lesplanning']" icon="lesplanning" data-gtm="naar-lesplanning">
                Naar lesplanning</dt-outline-button
            >
            <dt-outline-button class="text-weak" (click)="onCancelClick()" cy="rooster-preview-annuleren-button">
                Annuleren</dt-outline-button
            >
        </div>
    </div>
</dt-popup>
