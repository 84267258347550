<div class="tab-row">
    <hmy-tab
        *ngFor="let tab of tabs; trackBy: trackByLabel"
        [tab]="tab"
        [tabMode]="tabMode"
        [active]="activeTabLabel === tab.label"
        (click)="setActiveTab(tab.label)"
        tabindex="0"
        role="button"></hmy-tab>
</div>
