<span class="title text-content-small-semi">Instellingen</span>
<form [formGroup]="instellingenForm" (ngSubmit)="onSubmit()">
    <div class="setting-header">
        <span class="visible-info text-content-small-semi">Hoe laat begint het 1e uur op jouw school?</span>
        <i
            class="info fill-background-2"
            [class.active]="showBegintijdInfo"
            (click)="showBegintijdInfo = !showBegintijdInfo"
            hmyIcon="waarschuwing"
            size="medium"></i>
        <span class="hidden-info text-content-small" [@collapse]="{ value: !showBegintijdInfo }"
            >Vanaf dit moment tonen we de vrije uren langer dan 20 minuten.</span
        >
    </div>
    <div class="setting">
        <div class="tijd">
            <i class="fill-primary-1" hmyIcon="klok" size="small"></i>
            <input class="tijd-input" type="time" cy="vrije-uren-starttijd" dtTimeInputHelper required="required" formControlName="tijd" />
        </div>
        <button
            class="submit"
            [class.opgeslagen]="!instellingenForm.dirty"
            [class.hide-save]="hideSave"
            type="submit"
            data-gtm="gebruikers-instellingen-opslaan">
            @if (!instellingenForm.dirty) {
                <i class="fill-accent-positive-1" hmyIcon="check" size="smallest"></i>
            }
            @if (instellingenForm.dirty) {
                <span>Opslaan</span>
            }
        </button>
    </div>
</form>
