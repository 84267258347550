<div
    class="with-item"
    [class.large-number]="isLargeNumber()"
    [style.backgroundColor]="bgColor() | cssVar"
    [style.width]="width()"
    [style.height.px]="size()"
    [style.color]="fgColor() | cssVar"
    [style.borderColor]="borderColor()"
    [style.borderWidth.px]="border()?.size"
    [style.borderStyle]="border() ? 'solid' : 'none'"
    [attr.aria-label]="ariaLabel()">
    <span>{{ count() }}</span>
</div>
