<dt-menu class="show-for-phone-only"></dt-menu>
@if (metNavigatie) {
    <div class="custom-navigatie">
        <ng-content></ng-content>
    </div>
}
<div class="header">
    @if (!metNavigatie) {
        <div class="title text-heading-2"><i [hmyIcon]="icon" [sizeInPx]="24"></i>{{ titel }}</div>
    }
    @if (showDarkModeToggle) {
        <i class="pointer dark-mode-switch" (click)="toggleDarkMode()" color="action-primary-normal" hmyIcon="telefoon" size="large"></i>
    }
    <i
        class="header-icon"
        #zoeken
        (click)="openZoekenPopup()"
        hmyIcon="zoeken"
        color="action-primary-normal"
        size="large"
        dtTooltip="Zoeken (ctrl+spatie)"
        position="bottom"
        data-gtm="menu-item-zoek"></i>
    @if (heeftBerichtenInzienRecht$ | async) {
        <div class="berichten-container header-icon" (click)="openBerichtenSidebar()">
            <i
                color="action-primary-normal"
                hmyIcon="bericht"
                dtTooltip="Berichten"
                size="large"
                position="bottom"
                data-gtm="menu-item-berichten">
            </i>
            @if (aantalOngelezenBerichten$ | async; as aantalOngelezenBerichten) {
                @if (aantalOngelezenBerichten > 0) {
                    <div class="notificatie-counter">
                        {{ aantalOngelezenBerichten > 99 ? '99+' : aantalOngelezenBerichten }}
                    </div>
                }
            }
        </div>
    }
    <i
        class="header-icon feedback"
        #feedback
        (click)="openFeedbackOpties()"
        hmyIcon="feedback"
        color="action-primary-normal"
        size="large"
        dtTooltip="Feedback"
        position="bottom"
        cy="feedback"></i>
    @if ({ beschikbaar: isUpdateBeschikbaar$ | async }; as update) {
        <div class="avatar" #avatarContainer>
            @if (medewerker$ | async; as medewerker) {
                <dt-avatar
                    [src]="medewerker.pasfoto"
                    [initialen]="medewerker.initialen"
                    [bordersize]="2"
                    [allowPhotoPopup]="false"
                    [class.popup-open]="isPopupOpen"
                    [lazyLoaded]="false"
                    (click)="toggleProfileMenu(update.beschikbaar)"
                    dtTooltip="Instellingen"
                    position="bottom"
                    altTag="Account-opties - {{ medewerker | volledigeNaam }}"
                    cy="profiel"></dt-avatar>
            }
            @if (update.beschikbaar) {
                <i color="fg-warning-normal" hmyIcon="waarschuwing" size="medium"> </i>
            }
        </div>
    }
    <div
        class="naar-somtoday-knop hide-for-phone-only"
        [elementOffset]="-14"
        (click)="onSomtodayButtonClicked()"
        dtTooltip="{{ (isCoreReturnUrlSet$ | async) ? 'Terug naar Somtoday' : 'Naar Somtoday' }}"
        position="bottom"
        data-gtm="menu-item-terug-naar-somtoday">
        <i color="action-primary-normal" hmyIcon="terugNaarSomtoday" size="medium"></i>
    </div>
</div>
