<div class="hide-for-tablet actions">
    @for (customButton of buttons[0]; track customButton) {
        @if (customButton.isVerwijderButton) {
            <dt-verwijder-button
                [buttonText]="customButton.text"
                [icon]="customButton.icon"
                [withBorder]="true"
                [gtmTag]="customButton.gtmTag"
                (onDelete)="onActionClicked.emit(); customButton.onClickFn()"></dt-verwijder-button>
        } @else {
            <dt-outline-button
                class="custom-button"
                [color]="customButton.color"
                [icon]="customButton.icon"
                [ngClass]="customButton.customCssClass ?? ''"
                [attr.data-gtm]="customButton.gtmTag"
                [notificationCount]="customButton.notificationCount | async"
                (click)="onActionClicked.emit(); customButton.onClickFn()">
                {{ customButton.text }}</dt-outline-button
            >
        }
    }
    @if (buttons[1]) {
        <div class="divider-container">
            <div class="divider"></div>
        </div>
    }
    @for (customButton of buttons[1]; track customButton) {
        @if (customButton.isVerwijderButton) {
            <dt-verwijder-button
                [buttonText]="customButton.text"
                [icon]="customButton.icon"
                [withBorder]="true"
                [gtmTag]="customButton.gtmTag"
                (onDelete)="onActionClicked.emit(); customButton.onClickFn()"></dt-verwijder-button>
        } @else {
            <dt-outline-button
                class="custom-button"
                [color]="customButton.color"
                [icon]="customButton.icon"
                [ngClass]="customButton.customCssClass ?? ''"
                [attr.data-gtm]="customButton.gtmTag"
                [notificationCount]="customButton.notificationCount | async"
                (click)="onActionClicked.emit(); customButton.onClickFn()">
                {{ customButton.text }}</dt-outline-button
            >
        }
    }
</div>
<div class="show-for-tablet actions" [class.align-left]="alignLeft">
    @for (customButton of buttons[0]; track customButton) {
        @if (customButton.isVerwijderButton) {
            <dt-verwijder-button
                [buttonText]="customButton.text"
                [icon]="customButton.icon"
                [gtmTag]="customButton.gtmTag"
                (onDelete)="onActionClicked.emit(); customButton.onClickFn()">
            </dt-verwijder-button>
        } @else {
            <a
                class="custom-button"
                [color]="customButton.color || 'primary'"
                [icon]="customButton.icon"
                [ngClass]="customButton.customCssClass ?? ''"
                [attr.data-gtm]="customButton.gtmTag"
                (click)="onActionClicked.emit(); customButton.onClickFn()">
                {{ customButton.text }}</a
            >
        }
    }
    @if (buttons[1]) {
        <div class="divider-container">
            <div class="divider"></div>
        </div>
    }
    @for (customButton of buttons[1]; track customButton) {
        @if (customButton.isVerwijderButton) {
            <dt-verwijder-button
                class="no-height"
                [buttonText]="customButton.text"
                [icon]="customButton.icon"
                [gtmTag]="customButton.gtmTag"
                (onDelete)="onActionClicked.emit(); customButton.onClickFn()">
            </dt-verwijder-button>
        } @else {
            <a
                class="custom-button"
                [color]="customButton.color || 'primary'"
                [icon]="customButton.icon"
                [ngClass]="customButton.customCssClass ?? ''"
                [attr.data-gtm]="customButton.gtmTag"
                (click)="onActionClicked.emit(); customButton.onClickFn()">
                {{ customButton.text }}</a
            >
        }
    }
</div>
