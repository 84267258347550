<ng-content></ng-content>
<div class="tag-info">
    <span class="text text-content-smallest-semi" [class.ellipsis]="textEllipsis">{{ label }}</span>
    <i
        class="icon"
        *ngIf="icon !== 'none'"
        [class.is-close-icon]="icon === 'sluiten'"
        [hmyIcon]="icon"
        [attr.aria-label]="iconAriaLabel"
        [tabindex]="iconTabindex"
        (click)="iconClick.emit(); $event.stopPropagation()"
        role="button"
        size="smallest"></i>
</div>
