<button
    [type]="type()"
    [disabled]="disabled()"
    [ngClass]="[disabledOrMode(), size(), getLabelColor, justifyContent()]"
    [attr.tabindex]="customTabindex()"
    hmyClassOnClick="active">
    <div class="icon-container left" [style.marginRight]="leftIconMargin()">
        @if (iconLeft(); as icon) {
            <i [hmyIcon]="icon" [color]="getLabelColor" [size]="iconLeftSize()"></i>
        }
    </div>
    <span class="label">{{ label() }}</span>
    <div class="icon-container right" [style.marginLeft]="rightIconMargin()">
        @if (iconRight(); as icon) {
            <i [hmyIcon]="icon" [color]="getLabelColor" [size]="iconRightSize()"></i>
        }
    </div>
</button>
