<dt-popup>
    <div class="content" popup-content>
        <dt-deelnemer-selectie
            [formControl]="deelnemerSelectie"
            [focusOnInit]="true"
            [zoekveldIcon]="iconZoeken"
            zoekveldIconColor="action-primary-normal"
            placeholder="Zoek leerling, groep of medewerker">
        </dt-deelnemer-selectie>
    </div>
</dt-popup>
