<div class="container">
    <span class="notification" *ngIf="isNotificationIconTab(tab.notification) as notification">
        <hmy-notification-icon
            [icon]="notification.icon"
            [color]="notification.color"
            [inverted]="notification.inverted"
            [size]="notification.size"></hmy-notification-icon>
    </span>

    <span class="notification" *ngIf="isNotificationSolidTab(tab.notification) as notification">
        <hmy-notification-solid
            [color]="notification.color"
            [inverted]="notification.inverted"
            [size]="notification.size"></hmy-notification-solid>
    </span>

    <!-- Voeg bij een counter een punt toe zodat er bij de screen reader een pauze komt tussen het label en de counter hierna -->
    <span class="tab-naam" [attr.aria-label]="isNotificationCounterTab(tab.notification) ? tab.label + '.' : tab.label">
        {{ tab.label }}
    </span>

    <span class="notification" *ngIf="isNotificationCounterTab(tab.notification) as notification">
        <hmy-notification-counter
            [color]="notification.color"
            [count]="notification.count"
            [countLabel]="notification.countLabel"
            [inverted]="notification.inverted"
            [size]="notification.size"></hmy-notification-counter>
    </span>
</div>
